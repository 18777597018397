import React from 'react';
import AboutSection1 from './component/aboutSection1';
import Service from './component/service';
import Team from './component/team';

const AboutUs = () => {
  return (
    <div>
      <AboutSection1/>
      <Service/>
      <Team/>
    
    </div>
   
  );
}

export default AboutUs;
